import * as React from "react";

import { GeoApps, GeoAppsOptions, App, EditorOptions, ScreenTemplate } from "@mapgear/geoapps-ui-framework";
import { BigSmallSectionTemplate } from "./screen-templates/BigSmallSectionTemplate";
import { BigSmallSplitScreenSectionTemplate } from "./screen-templates/BigSmallSplitScreenSectionTemplate";
// import { ErrorTemplate } from "./screen-templates/ErrorTemplate";
import { FullscreenOverlayTemplate } from "./screen-templates/FullscreenOverlayTemplate";
import { FullscreenTemplate } from "./screen-templates/FullscreenTemplate";
import { SmallBigSectionTemplate } from "./screen-templates/SmallBigSectionTemplate";
import { SmallBigSplitScreenSectionTemplate } from "./screen-templates/SmallBigSplitScreenSectionTemplate";
import { Startscreen1Template } from "./screen-templates/Startscreen1Template";
import { Startscreen2Template } from "./screen-templates/Startscreen2Template";
import { LoadingScreen } from "./LoadingScreen";
import { AppLayout } from "./AppLayout";

export const Main = (): JSX.Element => {
    const loadingComponent = <LoadingScreen />;

    const geoappsOptions: GeoAppsOptions = {
        authorize: "oidc",
        authorizationMode: "silent",
        allowAnonymous: true
    };

    if (((window as any)._editMode)) {
        geoappsOptions.authorizationMode = "silent";
    }
    
    const editorOptions: EditorOptions = {
        app: {
            options: {
                public: {
                    show: true,
                    defaultValue: false
                },
                default: {
                    show: true
                }
            }
        },
        blocks: {
            deniedBlocks: [
                { id: "dc3c0c71-4048-423c-86a3-901a562b9fd2" }, // ChartBarBlock
                { id: "3670e9fa-facd-4a59-8f8d-20436b2fbbc7" }, // ChartDonutBlock
                { id: "46287144-75d7-4542-b800-de3190126ae4" }, // ChartLineBlock
                { id: "c94d8065-4b8e-4280-b98f-81030b3b8cf3" }, // TableBlock
                { id: "c03ebdeb-b2e4-4ce5-8dbe-76503666964d" }, // ChartNumberBlock
                { id: "531507b8-d20d-4aaa-9ca5-94d7cb5d6ef1" }, // ChartGaugeBlock
                { id: "e13256c5-a8c6-453a-99a1-84e6200e7693" }, // StackedBlock
                { id: "361c99dd-15c1-4d57-b7bf-ac6edccb3116" }, // ButtonBlock
                { id: "1fd5e760-f82e-4d76-b4c3-2d349cf012b0" }, // SingleLineTextInputBlock
                { id: "f8822d6f-0e0f-4666-bfee-e016148793d1" }, // DropDownBlock
                { id: "29b8b35e-4705-44a4-8e11-a245fe691102" }, // RadioButtonBlock
                { id: "a3ff58c1-3d66-4b27-baff-fc8875e7bce0" }, // TextAreaBlock
                { id: "880973d6-9403-4c44-80ff-43e2a78e2fbf" }, // CheckBoxBlock
                { id: "09a39328-25b0-43ed-9102-b704752bf24c" }, // HeadingBlock
                { id: "bfeb94c5-7b18-4451-83bf-b90d77332c13" }, // SimpleMapQuestion
                { id: "70ff996e-31bd-4ffd-a73c-fc7592dc87a3" }, // SliderBlock
                { id: "1ec2a9b2-5c3c-49be-b73d-14471bfe9442" }, // SmileyBlock
                { id: "5c6a7bf3-c4c1-4447-bdb2-fbb8e1555ab6" }, // CheckBoxImageBlock
                { id: "3470ee3f-77ab-4da9-a001-a89bd774082c" }, // MapQuestionBlock
            ]
        }
    }

    return (
        <GeoApps {...geoappsOptions}>
            <App editor={editorOptions} loadingComponent={loadingComponent}>
                <AppLayout />

                <ScreenTemplate type="STARTSCREEN_1">
                    <Startscreen1Template />
                </ScreenTemplate>

                <ScreenTemplate type="STARTSCREEN_2">
                    <Startscreen2Template />
                </ScreenTemplate>

                <ScreenTemplate type="FULL_SCREEN">
                    <FullscreenTemplate />
                </ScreenTemplate>

                <ScreenTemplate type="FULL_MODAL">
                    <FullscreenOverlayTemplate />
                </ScreenTemplate>

                <ScreenTemplate type="SMALL_BIG">
                    <SmallBigSectionTemplate />
                </ScreenTemplate>

                <ScreenTemplate type="BIG_SMALL">
                    <BigSmallSectionTemplate />
                </ScreenTemplate>

                <ScreenTemplate type="SMALL_BIG_SPLIT">
                    <SmallBigSplitScreenSectionTemplate />
                </ScreenTemplate>

                <ScreenTemplate type="BIG_SPLIT_SMALL">
                    <BigSmallSplitScreenSectionTemplate />
                </ScreenTemplate>

                {/* <ScreenTemplate>
                    <ErrorTemplate />
                </ScreenTemplate> */}
            </App>
        </GeoApps>
    );
};
