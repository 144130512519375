import * as React from "react";
import { BlockSlot, EditorBlockOptions } from "@mapgear/geoapps-ui-framework";

export const SmallBigSplitScreenSectionTemplate = (): JSX.Element => {

    const smallBlockEditorOptions: EditorBlockOptions = {
        allowedBlocks: [
            "c5c9c899-7792-4d10-8d94-02286f98d8b3", // Text
        ]
    }

    return (
        <div className="template small-big-section">
            <div className="small-section">
                <BlockSlot slot={0} />
            </div>
            <div className="big-section">
                <div className="splitscreen">
                    <div className="splitscreen-top">
                        <div className="splitscreen-column">
                            <BlockSlot slot={1} />
                        </div>
                        <div className="splitscreen-column">
                            <BlockSlot slot={2} />
                        </div>
                    </div>
                    <div className="splitscreen-bottom">
                        <BlockSlot editor={smallBlockEditorOptions} slot={3} />
                    </div>
                </div>
            </div>
        </div>
    );
};
