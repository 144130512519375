import * as React from "react";
import { BlockSlot} from "@mapgear/geoapps-ui-framework";

export const BigSmallSectionTemplate = (): JSX.Element => {

    return (
        <div className="template big-small-section">
            <div className="big-section">
                <BlockSlot slot={0}/>
            </div>
            <div className="small-section">
                <BlockSlot slot={1}/>
            </div>
        </div>
    );
};
