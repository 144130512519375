import React from "react";
import { useApp, ScreenSlot } from "@mapgear/geoapps-ui-framework";
// import { ShareOverlay } from "./ShareOverlay";
// import { HelpOverlay } from "./HelpOverlay";

import { Navigation } from "./Navigation";

export const AppLayout = (): JSX.Element => {

    return (
        <div id="maptour-container" className="loaded">
            <div id="gui">
                <main role="main">
                    <ScreenSlot documentTitle="TITLE" animation="horizontal-slide" />
                </main>
                <Navigation />
                {/* <ShareOverlay /> */}
                {/* <HelpOverlay /> */}
            </div>
            <div id="overlays"></div>
        </div>
    );
};