import * as React from "react";
import { BlockSlot} from "@mapgear/geoapps-ui-framework";

export const FullscreenTemplate = (): JSX.Element => {

    return (
        <div className="template fullscreen-template">
            <BlockSlot slot={0} />
        </div>
    );
};
