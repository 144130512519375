import * as React from "react";
import { Icon } from "../icon/icon";
 
export const SlickPrevBtn = (props): JSX.Element => {
    // onClick of Slick carousel
    const { onClick } = props;

    return (
        <div id="prevArrow" className="screenListControl screenListControlPrev" onClick={onClick}><Icon size="1em" icon="icon-backward" className="icon" /></div>
    );
}