import * as React from "react";
import { BlockSlot, useApp, Next } from "@mapgear/geoapps-ui-framework";
import { Icon } from "../icon/icon";

export const Startscreen2Template = (): JSX.Element => {
    const { app } = useApp();

    return (
        <section className="template startscreen-2-template">
            <div className="column leftColumn">
                <div className="columnHeader">
                    <div className="columnHeaderLeft">
                        <div className="columnLogo" role="img" aria-label="Logo"></div>
                    </div>
                    <div className="columnHeaderRight">
                        <div className="columnTitle">
                            {app.Name}
                        </div>
                    </div>
                </div>
                <div className="columnContent">
                    <BlockSlot slot={0} />
                    <div className="startbutton-wrap">
                        <Next className="start-btn">
                            <span>Start de tour</span>
                            <Icon size="1em" icon="icon-play" className="icon" />
                        </Next>
                    </div>
                </div>
            </div>
            <div className="column rightColumn">
                <BlockSlot slot={1} />
            </div>
        </section>
    );
}
