import * as React from "react";
import { BlockSlot} from "@mapgear/geoapps-ui-framework";

export const SmallBigSectionTemplate = (): JSX.Element => {

    return (
        <div className="template small-big-section">
            <div className="small-section">
                <BlockSlot slot={0} />
            </div>
            <div className="big-section">
                <BlockSlot slot={1} />
            </div>
        </div>
    );
};