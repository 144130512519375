import * as React from "react";

export const LoadingScreen = () => {
    return (
        <div className="loading-screen">
            <div className="loading-inner">
                <div className="loading-title">MapTour wordt geladen...</div>
                <div className="loading-spinner"></div>
            </div>
        </div>
    );
};
