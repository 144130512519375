import * as React from "react";
import { Icon } from "../icon/icon";
 
export const SlickNextBtn = (props): JSX.Element => {
    // onClick of Slick carousel
    const { onClick } = props;

    return (
        <div id="nextArrow" className="screenListControl screenListControlNext" onClick={onClick}><Icon size="1em" icon="icon-forward" className="icon" /></div>
    );
}