import * as React from "react";
import { BlockSlot, useApp, Next } from "@mapgear/geoapps-ui-framework";
import { Icon } from "../icon/icon";

export const Startscreen1Template = (): JSX.Element => {
    const { app } = useApp();

    return (
        <section className="template startscreen-1-template">
            <BlockSlot slot={0} />
            <div className="overlay">
                <div className="overlayWindow">
                    <div className="overlayHeader">
                        <div className="overlayTitle">
                            {app.Name}
                        </div>
                        <div className="overlayLogo" role="img" aria-label="Logo"></div>
                    </div>
                    <div className="overlayContent">
                        <BlockSlot slot={1} />
                        <div className="startbutton-wrap">
                            <Next className="start-btn">
                                <span>Start de tour</span>
                                <Icon size="1em" icon="icon-play" className="icon" />
                            </Next>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
