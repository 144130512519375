import React, { useEffect, useState } from "react";
import { Next, Previous, useApp, useAppNavigation, ScreenTitle, AppTitle, useGetScreensFromResources } from "@mapgear/geoapps-ui-framework";

import { SlickNextBtn } from "./slick/slick-next-btn";
import { SlickPrevBtn } from "./slick/slick-prev-btn";
import Slider from "react-slick";
import { Icon } from "./icon/icon";

export const Navigation = (): JSX.Element => {
    const { app } = useApp();
    const { currentScreenIndex, goToScreen } = useAppNavigation(app);
    const { screensObject } = useGetScreensFromResources();

    const [showNavigation, setShowNavigation] = React.useState<boolean>(false);

    let sliderRef = React.createRef();

    const sliderSettings = {
        centerMode: true,
        centerPadding: 0,
        dots: false,
        focusOnSelect: true,
        infinite: true,
        initialSlide: currentScreenIndex,
        nextArrow: <SlickNextBtn />,
        prevArrow: <SlickPrevBtn />,
        slidesToScroll: 1,
        slidesToShow: 3,
        speed: 300,
        responsive: [
            {
                breakpoint: 850,
                settings: "unslick",
            },
        ],
    };

    /**
     * Toggles the Navigation depending on current state
     */
    const toggleNavigation = () => {
        if (showNavigation) setShowNavigation(false);
        else setShowNavigation(true);
    };

    return (
        <>
            <header className="headerMobile">
                <div id="logo" role="img" aria-label="Logo"></div>
                <div className="tourTitle"><AppTitle /></div>
            </header>
            <footer id="footer">
                {/* Left column */}
                <div className="column leftColumn">
                    <div id="logo" role="img" aria-label="Logo"></div>
                    <div className="tourTitle"><AppTitle /></div>
                </div>
                {/* Center column */}
                <div id="chapters" className={"column centerColumn" + (showNavigation ? " navigationActive" : "")}>
                    <div className="navigationBar">
                        <button aria-label="Open schermnavigatie" className="navigationIcon" onClick={toggleNavigation}>
                            <Icon size="1em" icon="icon-menu" className="icon" />
                        </button>
                        <div className="screenList active">
                            <div className="screenListBackground">
                                <div className="column columnLeft"></div>
                                <div className="column"></div>
                                <div className="column columnRight"></div>
                            </div>
                            <div className="screenListWrapper">
                                <div className="screenListTitle">Navigatie</div>
                                <Slider {...sliderSettings} ref={(c) => (sliderRef = c)}>
                                    {screensObject?.map((screen, index) => {
                                        return (
                                            <div className={"screenListItem " +
                                                (currentScreenIndex === index ? "current-slide" : "") +
                                                (index === 0 ? " firstScreen" : "")}
                                                key={index}
                                                onClick={() => goToScreen(index)}
                                            >
                                                <span>{screen.screen.name}</span>
                                            </div>
                                        )
                                    })}
                                </Slider>
                            </div>
                            <div className="screenListClose" onClick={() => setShowNavigation(false)}>
                                <Icon size="1em" icon="icon-close" className="icon" />
                            </div>
                        </div>
                    </div>
                    <div id="chapter-title">
                        <div className="title"><ScreenTitle /></div>
                    </div>
                </div>
                {/* Right column */}
                <div className="column rightColumn">
                    <div className="icon-group is-hidden">
                        <i id="shareIcon" className="icon icon-share"></i>
                        {/* <i id="helpIcon" className="icon icon-help"></i> */}
                    </div>
                    <div role="navigation" id="button-group">
                        <Previous className="button button-prev "
                            onClick={() => {
                                setShowNavigation(false);
                                if (window.innerWidth > 850) {
                                    //@ts-ignore
                                    sliderRef.slickGoTo(currentScreenIndex - 1);
                                }
                            }}
                        >
                            <Icon size="1em" icon="icon-arrow-left" className="icon" />
                            <span>Vorige</span>
                        </Previous>
                        <Next className="button button-next "
                            onClick={() => {
                                setShowNavigation(false);
                                if (window.innerWidth > 850) {
                                    //@ts-ignore
                                    sliderRef.slickGoTo(currentScreenIndex + 1);
                                }
                            }}
                        >
                            <span>Volgende</span>
                            <Icon size="1em" icon="icon-arrow-right" className="icon" />
                        </Next>
                    </div>
                </div>
            </footer >
        </>
    );
};

