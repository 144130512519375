import * as React from "react";
import { BlockSlot} from "@mapgear/geoapps-ui-framework";

export const FullscreenOverlayTemplate = (): JSX.Element => {

    return (
        <div className="template fullscreen-overlay-template">
            <BlockSlot slot={0} />
            <div className="overlay">
                <div className="overlay-window">
                    <BlockSlot slot={1} />
                </div>
            </div>
        </div>
    );
};
